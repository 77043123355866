<template>
  <nav v-bind:style="{ background: navColorObject.background, borderBottom: navColorObject.borderBottom}">
    <div v-if="name" id="logo" @click="goto('top')" class="navElement" v-bind:style="{ color: navColorObject.color }">
      {{ name }}
    </div>
    <div v-else id="logo" @click="goto('top')" class="navElement">
      <img class="LogoImage" v-if="navColorObject.darkLogo" :src="require(`@/assets/logo/${logoImgDark}`)" alt="Logo" />
      <img class="LogoImage" v-else :src="require(`@/assets/logo/${logoImgWhite}`)" alt="Logo" />
    </div>


      <ul class="nav-links">
        <li v-for="list in navLinks" :key="list.key">
          <a v-if="list.dropdown === false" @click="goto(list.link)" class="navElement" v-bind:style="{ color: navColorObject.color }">{{ list.name }}</a>
          
          <div class="dropdown-link" v-else>
            <a :href="list.link">
              {{ list.name }}
              <span>&#x2193;</span>
            </a>
            <ul class="dropdown-menu">
              <li v-for="item in list.dropdownLinks" :key="item.key">
                <a :href="item.link">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>


    <div v-on:click="openMobileNav()" id="burger">
      <div class="line1" v-bind:style="{ backgroundColor: navColorObject.toggleColor }"></div>
      <div class="line2" v-bind:style="{ backgroundColor: navColorObject.toggleColor }"></div>
      <div class="line3" v-bind:style="{ backgroundColor: navColorObject.toggleColor }"></div>
    </div>
  </nav>


</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'Navbar',
  props: ['name', 'logoImgDark', 'logoImgWhite', 'navLinks'],
  data() {
        return {
            navColorObject: {
              'color' : 'white',
              'mobileColor' : 'rgb(74, 74, 76)',
              'background' : 'transparent',
              'opacity' : 1,
              'boxShadow' : '0 0px 0px 0 lightgrey',
              'borderBottom' : '0px solid lightgrey',
              'darkLogo' : false,
              'mobileNavActive' : false,
            }
        }
  },
  components: {
    
  },
  computed: {
    ...mapGetters({
      hiddenPercentage: "getHiddenPercentage"
    }),
  },
  methods: {
    openMobileNav() {
      const burger = document.getElementById('burger')
      const nav = document.querySelector('.nav-links')
      const navLinks = document.querySelectorAll('.nav-links li')
      // Toggle navigation on mobile
      nav.classList.toggle('nav-active')
      // Burger toggler
      burger.classList.toggle('toggle')
      // Set nav open font color
      this.navColorObject['color'] = 'rgb(74, 74, 76)';
      this.mobileNavActive = !this.navColorObject.mobileNavActive;
      // Animate navigation links
      navLinks.forEach((link, index) => {
        if (link.style.animation || link.style.webkitAnimation) {
          link.style.animation = ''
          link.style.webkitAnimation = ''
        } else {
          link.style.webkitAnimation = `navLinkFade 0.5s ease forwards ${
            index / 7
          }s`
          link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7}s`
        }
      })
    },
    setNavColors(){
      let windowWidth = window.innerWidth;
      if (windowWidth > 769) {
        this.mobileNavActive = false;
      }
      if (!window.scrollY >= 80) {
          if (!this.mobileNavActive) {
           this.navColorObject['color'] = 'rgb(74, 74, 76)' 
          }
          this.navColorObject['background'] = 'transparent'
          this.navColorObject['boxShadow'] = '0 0px 10px 0 lightgrey'
          this.navColorObject['darkLogo'] = true
          this.navColorObject["toggleColor"] = 'rgb(74, 74, 76)'
      } else {
          if (!this.mobileNavActive) {
              this.navColorObject['color'] = 'white'
          }

          this.navColorObject['background'] = 'transparent'
          this.navColorObject['boxShadow'] = '0 0px 0px 0 lightgrey'
          this.navColorObject['darkLogo'] = false
          this.navColorObject["toggleColor"] = 'white'
      }
      if (this.hiddenPercentage <= 0.65) {
        if (!this.mobileNavActive) {
            this.navColorObject['color'] = 'white'
        }
        this.navColorObject["toggleColor"] = 'white'
        this.navColorObject['darkLogo'] = false
      } else if (this.hiddenPercentage > 0.65){
        if (!this.mobileNavActive) {
          this.navColorObject['color'] = 'rgb(74, 74, 76)'
        }
        this.navColorObject['darkLogo'] = true
        this.navColorObject["toggleColor"] = 'rgb(74, 74, 76)'
      }
      if (this.hiddenPercentage > 0.95 && windowWidth < 1553) {
        this.navColorObject['background'] = 'white'
        this.navColorObject['borderBottom'] = '1px solid lightgrey'
      }else{
        this.navColorObject['background'] = 'transparent'
        this.navColorObject['borderBottom'] = '0px solid lightgrey'
      }
    },
    openDropdownNav() {
      const dropdownLink = document.querySelectorAll('.dropdown-link')
      dropdownLink.forEach((dropdown) => {
        dropdown.addEventListener('mouseover', () => {
          dropdown.children[1].style.display = 'block'
        })
        dropdown.addEventListener('mouseleave', () => {
          dropdown.children[1].style.display = 'none'
        })
      })
    },
    /* Run only on mobile
       
       This code below here will change the behavior of mobile menu links.
       when you click for once it will open the drop down & by the
       second click it will lead you to the link page if you don't
       want to go in dropdown links
    */
    countClicksOnMobileDropdown() {
      const dropdownLink = document.querySelectorAll('.dropdown-link')
      dropdownLink.forEach((dropdown) => {
        let counts = dropdown.clicks || 0
        dropdown.addEventListener('click', () => {
          counts++
          if (counts % 2 == 0) {
            window.location.href = dropdown.children[0].getAttribute('href')
          } else {
            event.preventDefault()
            dropdown.children[1].style.display = 'block'
            setTimeout(() => {
              dropdown.children[1].style.display = 'none'
            }, 5000)
          }
        })
        setTimeout(()=>{counts = 0}, 8000)
      })
    },
    goto(refName) {
      this.$emit("goto", refName);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.setNavColors);
    window.addEventListener("resize", this.setNavColors);
    this.openDropdownNav()
    if (window.innerWidth < 768) {
      this.countClicksOnMobileDropdown()
    }
  },
}
</script>

<style>

li{
  display: flex;
    align-items: center;
}

.LogoImage{
  height: 50px;
  padding-top: 10px;
}

.navElement{
  cursor: pointer;
}
.navElement:hover{
  font-weight: bold;
}
nav {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 99;
  display: flex;
  align-items: center;
  background-color: white;
  min-height: 62px;
  font-family: 'Montserrat', sans-serif;
  padding: 0px 30px 0px 30px;
}
div#logo {
  color: rgb(74, 74, 76);
  font-weight: bold;
  font-size: 2rem;
}
ul.nav-links {
  display: flex;
  align-self: center;
  margin-left: auto;
}
ul.nav-links li {
  list-style: none;
  margin-left: 16px
}
ul.nav-links a {
  text-decoration: none;
  color: rgb(74, 74, 76);
  font-size: 1.2rem;
  font-weight: 500;
  display: block;
}
#burger {
  display: none;
  cursor: pointer;
  margin-left: auto;
}
#burger div {
  width: 30px;
  height: 3px;
  margin: 8px;
  transition: all 0.3s ease-in;
}
ul.dropdown-menu {
  position: absolute;
  top: 8vh;
  background-color: white;
  min-width: 140px;
  cursor: pointer;
  display: none;
}
ul.dropdown-menu li:first-child {
  margin: 0 0 10px 0;
}
ul.dropdown-menu li {
  margin: 10px 0;
}
ul.dropdown-menu li:last-child {
  margin: 10px 0 0 0;
}
ul.dropdown-menu a {
  line-height: 8vh;
  padding: 5px 15px;
  background-color: rgb(74, 74, 76);
  line-height: 50px;
}
/* Tablet */
@media screen and (max-width: 1024px) {
  ul.nav-links {
  }
}
/* Mobile */
@media screen and (max-width: 768px) {
  ul.nav-links {
    position: absolute;
    flex-direction: column;
    height: 100vh;
    top: 6.8vh;
    right: 0;
    padding: 100px;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    opacity: 0.8;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    min-width: 100%;
  }
  ul.nav-links li {
    opacity: 0;
  }
  ul.nav-links a {
    width: 100%;
    margin: 10px;
  }
  div#burger {
    display: block;
  }
  ul.dropdown-menu {
    position: relative;
    top: 0;
  }
}
.nav-active {
  transform: translateX(0) !important;
}
@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(-60px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
.toggle .line1 {
  transform: rotate(-45deg) translate(-9px, 10px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
</style>